import "./App.css";
import { Outlet } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TokenProvider } from "./context/TokenContext";
import { AppProvider } from "./context/AppContext";

function App() {
  return (
    <>
      <TokenProvider>
        <AppProvider>
          <ToastContainer position="top-center" />
          <Header />
          <main className="min-h-[calc(100vh-120px)] pt-16">
            <Outlet />
          </main>
          <Footer />
        </AppProvider>
      </TokenProvider>
    </>
  );
}

export default App;
