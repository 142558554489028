import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import UploadProduct from "../components/UploadProduct";
import SummaryApi from "../common";
import AdminProductCard from "../components/AdminProductCard";
import useFetch from "../utils/useFetch";

const AllProducts = () => {
  const [openUploadProduct, setOpenUploadProduct] = useState(false);
  const [allProduct, setAllProduct] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setpageCount] = useState(0);
  let limit = 25;

  let api = useFetch();

  const fetchAllProduct = async () => {
    const url = new URL(SummaryApi.allProduct.url);
    console.log("page - fetchAllProduct", { page });
    console.log("page - fetchAllProduct2", `${page}`);
    url.searchParams.set("page", `${page}`);

    /*
    const response = await fetch(url, {
      credentials: "include",
    });
    const dataResponse = await response.json();

    console.log("product data", dataResponse);
    // paging

    const total = dataResponse.paging.Products.count; //res.headers.get("x-total-count");
    */

    let config = {
      method: SummaryApi.allProduct.method,
      // body: JSON.stringify({username: "example"}),
      headers: {
        "Content-Type": "application/json",
      },
    };
    let { response, data } = await api(url, config);
    //console.log('response : ', response)
    console.log("product data : ", data);

    const total = data.paging.count;

    setpageCount(Math.ceil(total / limit));
    // console.log(Math.ceil(total/12));
    //setItems(data);

    // end paging

    setAllProduct(data?.data || []);
    //setPage(dataResponse?.data?.paging?.page || 1)
  };
  const handlePageClick = async (data) => {
    console.log(data.selected);

    let currentPage = data.selected + 1;
    setPage(currentPage);
    await fetchAllProduct();

    //const commentsFormServer = await fetchComments(currentPage);

    //setItems(commentsFormServer);

    // scroll to the top
    //window.scrollTo(0, 0)
  };
  useEffect(() => {
    fetchAllProduct();
  }, []);

  return (
    <div>
      <div className="bg-white py-2 px-4 flex justify-between items-center">
        <h2 className="font-bold text-lg">All Product</h2>
        <button
          className="border-2 border-red-600 text-red-600 hover:bg-red-600 hover:text-white transition-all py-1 px-3 rounded-full "
          onClick={() => setOpenUploadProduct(true)}
        >
          Upload Product
        </button>
      </div>

      {/**all product */}
      <div className="flex items-center flex-wrap gap-5 py-4 h-[calc(100vh-190px)] overflow-y-scroll">
        {allProduct.map((product, index) => {
          return (
            <AdminProductCard
              data={product}
              key={index + "allProduct"}
              fetchdata={fetchAllProduct}
            />
          );
        })}
      </div>

      {/**upload prouct component */}
      {openUploadProduct && (
        <UploadProduct
          onClose={() => setOpenUploadProduct(false)}
          fetchData={fetchAllProduct}
        />
      )}

      <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
        <ReactPaginate
          previousLabel={"previous"}
          nextLabel={"next"}
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination justify-content-center"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
        />
      </div>
    </div>
  );
};

export default AllProducts;
