import { createContext, useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import SummaryApi from "../common";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserDetails } from "../store/userSlice";

const TokenContext = createContext(null);
export default TokenContext;

export const TokenProvider = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cartProductCount, setCartProductCount] = useState(0);
  let [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  let [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwtDecode(localStorage.getItem("authTokens"))
      : null
  );
  let signInUrl = SummaryApi.signIn.url;

  const fetchUserDetails = async (authTokens2) => {
    let url = SummaryApi.current_user.url;
    let config = {
      method: SummaryApi.current_user.method,
      // body: JSON.stringify({username: "example"}),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${authTokens2?.access}`,
      },
    };
    let response = await fetch(url, config);
    let data = await response.json();
    console.log("data : ", data);

    const dataApi = data; //await data.json();
    if (dataApi.success) {
      console.log("fetchUserDetails : dataApi : ", dataApi);
      dispatch(setUserDetails(dataApi.data));
    }
  };
  const fetchUserAddToCart = async (authTokens2) => {
    //console.log('call fetchUserAddToCart')
    let url = SummaryApi.addToCartProductCount.url;
    let method = SummaryApi.addToCartProductCount.method;
    let config = {
      method: method,
      // body: JSON.stringify({username: "example"}),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${authTokens2?.access}`,
      },
    };

    let response = await fetch(url, config);
    let dataApi = await response.json();
    console.log("data : ", dataApi);

    setCartProductCount(dataApi?.data?.count);
    //console.log('setCartProductCount : ', cartProductCount)
  };

  async function getDataGuestUser() {
    return await fetch(SummaryApi.signUP.url, {
      method: SummaryApi.signUP.method,
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ guest: true }),
    });
  }

  function LogInAndfetchGuestUserDetails() {
    (async () => {
      let r = await getDataGuestUser();
      let d = await r.json();
      if (d.success) {
        console.log("user_guest", d);
        setAuthTokens((prevAuthTokens) => {
          const newCount = d.data;
          console.log("newCount", newCount); // This will log the updated value of count
          return newCount;
        });
        setUser((prevAuthTokens) => {
          return jwtDecode(d.data.access);
        });

        localStorage.setItem("authTokens", JSON.stringify(d.data));
        fetchUserDetails(d.data);
        fetchUserAddToCart(d.data);
      }
      if (d.error) {
        toast.error(d.message);
      }
    })();
  }

  let loginUser = async (e) => {
    e.preventDefault();
    let response = await fetch(signInUrl, {
      method: SummaryApi.signIn.method,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: e.target.email.value,
        password: e.target.password.value,
      }),
    });

    let dataApi = await response.json();
    console.log(dataApi);

    if (dataApi.success) {
      setAuthTokens(dataApi.data);
      setUser(jwtDecode(dataApi.data.access));
      localStorage.setItem("authTokens", JSON.stringify(dataApi.data));
      fetchUserDetails(dataApi.data);
      fetchUserAddToCart(dataApi.data);
      //toast.success(dataApi.message);
      navigate("/");
    }
    // else {
    //     alert('Something went wrong!')
    // }
    if (dataApi.error) {
      toast.error(dataApi.message);
    }
  };

  let logoutUser = () => {
    setAuthTokens(null);
    setUser(null);
    localStorage.removeItem("authTokens");
    //toast.success(data.message)
    dispatch(setUserDetails(null));
    navigate("/");
  };

  let contextData = {
    user: user,
    cartProductCount: cartProductCount,
    authTokens: authTokens,
    setAuthTokens: setAuthTokens,
    setUser: setUser,
    loginUser: loginUser,
    logoutUser: logoutUser,
    setCartProductCount: setCartProductCount,
  };

  useEffect(() => {
    if (authTokens) {
      setUser(jwtDecode(authTokens.access));
    } else {
      console.log("Non connected");
      LogInAndfetchGuestUserDetails();
    }
  }, [authTokens]);

  return (
    <TokenContext.Provider value={contextData}>
      {children}
    </TokenContext.Provider>
  );
};
