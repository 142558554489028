import React from "react";

const Footer = () => {
  const mystyle = {
    position: "fixed",
    left: "0",
    bottom: "0",
    width: "100%",
    color: "black",
    backgroundColor: "#dee3e7",
  };
  return (
    <footer style={mystyle}>
      <div className="container mx-auto p-4">
        <p className="text-center font-bold" title="Youtube Channel">
          Dynamic Coding with Best
        </p>
      </div>
    </footer>
  );
};

export default Footer;
