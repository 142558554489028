import { useContext } from "react";
import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";
import SummaryApi from "../common";
import TokenContext from "../context/TokenContext";

export const useFetch = () => {
  let config = {};

  let { authTokens, setAuthTokens, setUser, logoutUser } =
    useContext(TokenContext);

  let baseURL = SummaryApi.apiUrl; //'http://127.0.0.1:8000'

  let originalRequest = async (url, config) => {
    //url = `${baseURL}${url}`
    url = `${url}`;
    let response = await fetch(url, config);
    let data = await response.json();
    //console.log('REQUESTING:', data)
    return { response, data };
  };

  let refreshToken = async (authTokens) => {
    const url = new URL(SummaryApi.tokenRefresh.url);
    //const refresh = authTokens.refresh;
    //url.searchParams.set("token", `${refresh}`);
    //console.log('SummaryApi.tokenRefresh.url', {refresh});

    let response = await fetch(url, {
      // credentials: 'include',
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ refresh: authTokens.refresh }),
    });

    let data = await response.json();
    if (response.status === 200) {
      localStorage.setItem("authTokens", JSON.stringify(data.data));
      setAuthTokens(data.data);
      setUser(jwtDecode(data.data.access));
      return data.data;
    } else {
      localStorage.removeItem("authTokens");
      return null;
    }
  };

  return async (url, config) => {
    const user = jwtDecode(authTokens.access);
    const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

    console.log("useFetch - isExpired", config);

    if (isExpired) {
      authTokens = await refreshToken(authTokens);
    }

    console.log("useFetch - first call config", config);

    config["headers"] = {
      Authorization: `Bearer ${authTokens?.access}`,
      "Content-Type": "application/json",
    };
    //console.log('config', config)

    let { response, data } = await originalRequest(url, config);

    if (data.message === "unauthenticated") {
      data = { message: "Session expired", error: true };
      logoutUser();
    }
    return { response, data };
  };
};

export default useFetch;
