import React from "react";
import BannerProduct from "../components/BannerProduct";
import CategoryList from "../components/CategoryList";
import HorizontalCardProduct from "../components/HorizontalCardProduct";
import VerticalCardProduct from "../components/VerticalCardProduct";

const Home = () => {
  return (
    <div>
      {/* <CategoryList /> */}
      <BannerProduct />

      <VerticalCardProduct category={"airpodes"} heading={"Top's Airpodes"} />
      <VerticalCardProduct category={"watches"} heading={"Popular's Watches"} />

      <VerticalCardProduct category={"airpodes"} heading={"Airpodes"} />
      {/* <VerticalCardProduct category={"mobiles"} heading={"Mobiles"} /> */}
      <VerticalCardProduct category={"mouse"} heading={"Mouse"} />
      {/* <VerticalCardProduct category={"televisions"} heading={"Televisions"} /> */}
      <VerticalCardProduct
        category={"camera"}
        heading={"Camera & Photography"}
      />
      <VerticalCardProduct category={"earphones"} heading={"Wired Earphones"} />
      <VerticalCardProduct
        category={"speakers"}
        heading={"Bluetooth Speakers"}
      />
      {/* <VerticalCardProduct category={"refrigerator"} heading={"Refrigerator"} /> */}
      <VerticalCardProduct category={"trimmers"} heading={"Trimmers"} />
      {/* <VerticalCardProduct category={"processor"} heading={"Processors"} /> */}
    </div>
  );
};

export default Home;
