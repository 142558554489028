const apiKey = process.env.REACT_APP_API_KEY;
const apiUrl = process.env.REACT_APP_API_URL;

//console.log(`API Key: ${apiKey}`);
//console.log(`API URL: ${apiUrl}`);

const backendDomin = apiUrl;

const SummaryApi = {
    apiUrl: {
        url: apiUrl,
    },
    signUP: {
        url: `${backendDomin}/api/users/create`,
        method: "post",
    },
    tokenRefresh: {
        url: `${backendDomin}/api/token/refresh`,
        method: "post",
    },
    signIn: {
        url: `${backendDomin}/api/users/login`,
        method: "post",
    },
    current_user: {
        //url: `${backendDomin}/api/user-details`,
        url: `${backendDomin}/api/users/details`,
        method: "get",
    },
    logout_user: {
        url: `${backendDomin}/api/users-logout`,
        method: "get",
    },
    allUser: {
        url: `${backendDomin}/api/users-list`,
        method: "get",
    },
    updateUser: {
        url: `${backendDomin}/api/users-update`,
        method: "post",
    },
    uploadProduct: {
        url: `${backendDomin}/api/upload-product`,
        method: "post",
    },
    allProduct: {
        url: `${backendDomin}/api/get-product`,
        method: "get",
    },
    updateProduct: {
        url: `${backendDomin}/api/update-product`,
        method: "post",
    },
    categoryProduct: {
        //url: `${backendDomin}/api/get-categoryProduct`,
        url: `${backendDomin}/api/categories-list`,
        method: "get",
    },
    categoryWiseProduct: {
        url: `${backendDomin}/api/category-product`,
        method: "post",
    },
    productDetails: {
        url: `${backendDomin}/api/product-details`,
        method: "post",
    },
    addToCartProduct: {
        url: `${backendDomin}/api/addtocart`,
        method: "post",
    },
    addToCartProductCount: {
        url: `${backendDomin}/api/countAddToCartProduct`,
        method: "get",
    },
    addToCartProductView: {
        url: `${backendDomin}/api/view-card-product`,
        method: "get",
    },
    updateCartProduct: {
        url: `${backendDomin}/api/update-cart-product`,
        method: "post",
    },
    deleteCartProduct: {
        url: `${backendDomin}/api/delete-cart-product`,
        method: "post",
    },
    searchProduct: {
        url: `${backendDomin}/api/search`,
        method: "get",
    },
    filterProduct: {
        url: `${backendDomin}/api/filter-product`,
        method: "post",
    },
    checkoutCart: {
        url: `${backendDomin}/api/checkout-cart`,
        method: "post",
    }, 
    uploadImage: {
        url: `${backendDomin}/api/upload-image`,
        method: "post",
    },
};

export default SummaryApi;
