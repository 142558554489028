import SummaryApi from "../common";
import { toast } from "react-toastify";
import customFetcher from "../utils/fetchInstance";

//
const addToCart = async (e, id, setAuthTokens, setUser, logoutUser) => {
  e?.stopPropagation();
  e?.preventDefault();

  const postsData = {
    product_id: id,
  };

  let url = SummaryApi.addToCartProduct.url;
  let config = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(postsData),
  };
  let { response, data } = await customFetcher(
    url,
    config,
    setAuthTokens,
    setUser,
    logoutUser
  );
  //console.log('response : ', response)
  //console.log("data : ", data);

  if (!data.error) {
    //toast.success(data.message);
  }

  if (data.error) {
    toast.error(data.message);
  }

  return response;
};

export default addToCart;
